import env from '@/modules/env';
import axiosBase from 'axios';
import store from '@/store';
import { isNotDevelopment } from '@/modules/nodeEnv';

export const createClient = options => {
  const baseOptions = {
    baseURL: env.API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  };
  return axiosBase.create({ ...baseOptions, ...options });
};
const makuakeApiClient = createClient({
  withCredentials: true,
});

const phpApiClient = createClient({
  headers: {
    'Accept-Language': 'ja',
  },
  baseURL: env.PHP_API_BASE_URL,
  // `validateStatus` defines whether to resolve or reject the promise for a given
  // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
  // or `undefined`), the promise will be resolved; otherwise, the promise will be
  // rejected.
  validateStatus(status) {
    return (status >= 200 && status < 300) || status === 400;
  },
});

const paymentApiClient = createClient({
  baseURL: env.PAYMENT_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

phpApiClient.interceptors.request.use(config => {
  /* eslint-disable no-param-reassign */
  config.headers['Accept-Language'] = store.state.headerAcceptLanguage;
  return config;
});

// Response interceptor
phpApiClient.interceptors.response.use(
  response => response,
  error => {
    if (!error || !error.response) {
      return Promise.reject(error);
    }

    const { status } = error.response;
    // 500エラー
    if (status >= 500 && isNotDevelopment()) {
      // エラー画面へ遷移
      window.location.assign('/_500_');
    }
    // 未ログイン
    if (status === 403) {
      store.setIsLogin(false);
    }
    return Promise.reject(error);
  },
);

// 認証用トークンのリフレッシュを行う
// 並行に実行されて無駄なリフレッシュが発生しないように, 可能ならば直列化する
const refreshIdToken = async () => {
  // LockManagerに非対応ブラウザの場合の対応
  // @see https://developer.mozilla.org/ja/docs/Web/API/LockManager/request
  if (navigator.locks === undefined || navigator.locks === null) {
    const response = await phpApiClient.get(`/userstatus/userstatus`);

    if (!response.data.is_logined) {
      throw Error('not logged in');
    }

    return;
  }

  await navigator.locks.request('refreshIdToken', async () => {
    const response = await phpApiClient.get(`/userstatus/userstatus`);

    if (!response.data.is_logined) {
      throw Error('not logged in');
    }
  });
};

makuakeApiClient.interceptors.response.use(
  response => response,
  error => {
    if (!error || !error.response || !error.response.data) {
      return Promise.reject(error);
    }
    const { status } = error.response;
    const { code } = error.response.data;
    if (status === 401 && code === 'expired_id_token') {
      return refreshIdToken()
        .then(async () => makuakeApiClient.request(error.config))
        .catch(() => {
          store.setIsLogin(false);

          const currentURL = new URL(window.location);
          window.location.assign(`/login/?ref=${currentURL.pathname}`);
        });
    }
    return Promise.reject(error);
  },
);

paymentApiClient.interceptors.response.use(
  response => response,
  error => {
    if (!error || !error.response || !error.response.data) {
      return Promise.reject(error);
    }
    const { status } = error.response;
    const { code } = error.response.data;
    if (status === 401 && code === 'token_expired') {
      return refreshIdToken()
        .then(async () => paymentApiClient.request(error.config))
        .catch(() => {
          store.setIsLogin(false);

          const currentURL = new URL(window.location);
          window.location.assign(`/login/?ref=${currentURL.pathname}`);
        });
    }
    return Promise.reject(error);
  },
);

export default {
  makuakeApiClient,
  phpApiClient,
  paymentApiClient,
};
