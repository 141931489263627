import { reactive } from 'vue';

export default {
  state: reactive({
    projectId: null,
    questions: [],
    enqueteIsLoaded: false,
    enqueteHasSubmitted: false,
  }),
};
