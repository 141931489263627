import { createApp } from 'vue';
import HasTicket from '@/pc/components/project/ticket/HasTicket';

const el = document.getElementById('vue-has-ticket');

if (el !== null) {
  const { projectId } = el.dataset;
  const { maxReturnPrice } = el.dataset;
  const tagIDs = el.dataset.tagIds.split(',');

  const app = createApp(HasTicket, { projectId, maxReturnPrice, tagIDs });
  app.mount('#vue-has-ticket');
}
