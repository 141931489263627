/* eslint-disable import/prefer-default-export */
export const EC_ONCE_BUY_MAX_AMOUNT = 10;
export const EC_MIN_AGE_ALCOHOL = 20;
export const INVESTMENT_TYPE_EC = 'ec';
export const INVESTMENT_TYPE_CF = 'cf';
export const LANG_JA = 'ja';
export const LANG_EN = 'en';
export const PROJECT_TYPE_ALL_IN = 5;
export const COOKIE_ORDER_ID = 'payment_order_id';
export const COOKIE_PRODUCT_ID = 'payment_product_id';
export const COOKIE_COUPON_ID = 'payment_coupon_id';
export const PAY_TYPE_CVS = 3;
export const PAY_TYPE_PAY_EASY = 4;
export const PAY_TYPE_GANB = 36;
export const USER_ADDRESS_LIMIT = 3;
