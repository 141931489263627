import {
  LANG_EN,
  LANG_JA,
  INVESTMENT_TYPE_EC,
  INVESTMENT_TYPE_CF,
  PROJECT_TYPE_ALL_IN,
} from '@/consts/payment';
import { reactive } from 'vue';

export default {
  state: reactive({
    lang: null,
    investmentType: null,
    isGovernment: false,
    isDonation: false,
    isAllIn: false,
    returnId: null,
    productId: null,
    projectCode: null,
    addressId: null,
    returnQa: {},
    isConfirmedAge: false,
    isAgreedAddressPolicy: false,
    isAgreedShippingPolicy: false,
    productQuantity: '',
    orderId: null,
    newCardFlag: false,
    isCommentSubmitted: false,
    shareTokenAndLink: {},
    pageFrom: null,
    ticket: null,
  }),
  /**
   * actions
   */
  setReturnId(returnId) {
    this.state.returnId = returnId;
  },
  setProductId(productId) {
    this.state.productId = productId;
  },
  setProjectCode(projectCode) {
    this.state.projectCode = projectCode;
  },
  setAddressId(addressId) {
    this.state.addressId = addressId;
  },
  setLang(lang) {
    this.state.lang = lang;
  },
  setInvestmentType(investmentType) {
    this.state.investmentType = investmentType;
  },
  setReturnQa(returnQa) {
    this.state.returnQa = returnQa;
  },
  setIsGovernment(isGovernment) {
    this.state.isGovernment = isGovernment;
  },
  setIsDonation(isDonation) {
    this.state.isDonation = isDonation;
  },
  setIsAllIn(type) {
    this.state.isAllIn = type === PROJECT_TYPE_ALL_IN;
  },
  setProductQuantity(quantity) {
    this.state.productQuantity = quantity;
  },
  setIsConfirmedAge(isConfirmedAge) {
    this.state.isConfirmedAge = isConfirmedAge;
  },
  setIsAgreedAddressPolicy(isAgreedAddressPolicy) {
    this.state.isAgreedAddressPolicy = isAgreedAddressPolicy;
  },
  setIsAgreedShippingPolicy(isAgreedShippingPolicy) {
    this.state.isAgreedShippingPolicy = isAgreedShippingPolicy;
  },
  setOrderId(orderId) {
    this.state.orderId = orderId;
  },
  setNewCardFlag(newCardFlag) {
    this.state.newCardFlag = newCardFlag;
  },
  setIsCommentSubmitted(isCommentSubmitted) {
    this.state.isCommentSubmitted = isCommentSubmitted;
  },
  setShareTokenAndLink(shareTokenAndLink) {
    this.state.shareTokenAndLink = shareTokenAndLink;
  },
  setPageFrom(pageFrom) {
    this.state.pageFrom = pageFrom;
  },
  setTicket(ticket) {
    this.state.ticket = ticket;
  },
  /**
   * getters
   */
  isCf() {
    return this.state.investmentType === INVESTMENT_TYPE_CF;
  },
  isEc() {
    return this.state.investmentType === INVESTMENT_TYPE_EC;
  },
  isEn() {
    return this.state.lang === LANG_EN;
  },
  isJa() {
    return this.state.lang === LANG_JA;
  },
};
