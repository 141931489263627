import payment from '@/store/payment';
import thankYou from '@/store/thankYou';
import { reactive } from 'vue';

export default {
  state: reactive({
    isLogin: false,
    headerAcceptLanguage: 'ja',
  }),
  setIsLogin(isLogin) {
    this.state.isLogin = isLogin;
  },
  setHeaderAcceptLanguage(acceptLanguage) {
    this.state.headerAcceptLanguage = acceptLanguage;
  },
  payment,
  thankYou,
};
