export default {
  methods: {
    calcDiscountedPrice(ticket, price) {
      let discountedPrice;
      const DISCOUNT_TYPE_MONEY_OFF = 1; // 定額割引
      const DISCOUNT_TYPE_PER_OFF = 2; // 定率割引

      switch (ticket.discount_type) {
        case DISCOUNT_TYPE_MONEY_OFF:
          discountedPrice = price - ticket.amount;
          break;
        case DISCOUNT_TYPE_PER_OFF:
          discountedPrice = price - Math.round((price * ticket.amount) / 100);
          break;
        default:
          discountedPrice = price;
      }

      const margin = price - discountedPrice;
      if (
        ticket.max_discount_price != null &&
        margin > ticket.max_discount_price
      ) {
        discountedPrice = price - ticket.max_discount_price;
      }

      return discountedPrice;
    },
    calcDiscountPrice(ticket, price) {
      let discountPrice;
      const DISCOUNT_TYPE_MONEY_OFF = 1; // 定額割引
      const DISCOUNT_TYPE_PER_OFF = 2; // 定率割引

      switch (ticket.discount_type) {
        case DISCOUNT_TYPE_MONEY_OFF:
          discountPrice = ticket.amount;
          break;
        case DISCOUNT_TYPE_PER_OFF:
          discountPrice = Math.round((price * ticket.amount) / 100);
          break;
        default:
          discountPrice = 0;
      }

      if (
        ticket.max_discount_price != null &&
        discountPrice > ticket.max_discount_price
      ) {
        discountPrice = ticket.max_discount_price;
      }

      return discountPrice;
    },
    /**
     * 利用可能なクーポンであるか判定します
     * @param ticket
     * @param price クーポン利用判定対象リターンの金額
     * @returns {boolean}
     */
    isUsable(ticket, price) {
      return (
        price >= ticket.min_need_price &&
        this.calcDiscountedPrice(ticket, price) >= 1
      );
    },
  },
};
