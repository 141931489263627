import client from '@/modules/api/createClient';

const fetchTicket = projectId => {
  if (projectId != null) {
    return client.makuakeApiClient.get(`/v2/ticket?project_id=${projectId}`);
  }

  return client.makuakeApiClient.get(`/v2/ticket`);
};

const acquireTicket = ticketId =>
  client.makuakeApiClient.post(`/v2/ticket/acquire`, { ticket_id: ticketId });

const useTicketCode = ticketCode =>
  client.makuakeApiClient.post(`/v2/ticket/code`, { ticket_code: ticketCode });

const autograntTicketOnPurchase = orderId =>
  client.makuakeApiClient.post(`/v2/ticket/autogrant/purchase`, {
    order_id: orderId,
  });

const fetchPublicTicket = ({ page, perPage, sort }) =>
  client.makuakeApiClient.get(`/v2/ticket/public`, {
    params: {
      page,
      per_page: perPage,
      sort,
    },
  });

export default {
  fetchTicket,
  fetchPublicTicket,
  acquireTicket,
  useTicketCode,
  autograntTicketOnPurchase,
};
